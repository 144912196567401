/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useRef } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import Slider from "react-slick"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useGlobalContext } from "../../context/layout-context"

const TitleUnderlined = dynamic(() => import("../title/title-underlined"), {
  ssr: true,
})

function AboutUsSlider({ slides, printTitle, classList, havePadding }) {
  const { updateGlobalState } = useGlobalContext()
  const sliderRef = useRef()
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    draggable: true,
    arrows: false,
    centerMode: false,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Slider
      {...settings}
      ref={sliderRef}
      className={`w-full relative block bg-cream-100 pb-10 ${classList}`}
    >
      {slides &&
        slides.map((block, key) => (
          <ul className="outline-none" key={`slider${key}`}>
            <li className="mb-0" data-layer-menu_item={block.header}>
              <div className="w-full">
                {block.thumbnail && block.thumbnail.image ? (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={
                      block.videoId && block.source
                        ? () => {
                            updateGlobalState({
                              modalVideoOpen: true,
                              videoId: block.videoId,
                              videoSrc: block.source,
                            })
                          }
                        : () => {
                            updateGlobalState({
                              modalVideoOpen: false,
                              videoId: null,
                              videoSrc: null,
                            })
                          }
                    }
                    onKeyPress={
                      block.videoId && block.source
                        ? () => {
                            updateGlobalState({
                              modalVideoOpen: true,
                              videoId: block.videoId,
                              videoSrc: block.source,
                            })
                          }
                        : () => {
                            updateGlobalState({
                              modalVideoOpen: false,
                              videoId: null,
                              videoSrc: null,
                            })
                          }
                    }
                  >
                    <Image
                      sizes="80vw"
                      src={block.thumbnail.image.url}
                      alt={block.thumbnail.image.captionAlt || block.thumbnail.image.title || block.thumbnail.image.fileName}
                      layout="responsive"
                      priority="true"
                      width={block.thumbnail.image.width}
                      height={block.thumbnail.image.height}
                    />
                  </div>
                ) : block.cover ? (
                  <Image
                    sizes="50vw"
                    src={block.cover.url}
                    alt={block.cover.captionAlt || block.cover.title || block.cover.fileName}
                    layout="responsive"
                    priority="true"
                    width={block.cover.width}
                    height={block.cover.height}
                  />
                ) : null}
                {printTitle ? (
                  <div className={`${havePadding || "pl-20 pr-20"} pt-10`}>
                    <TitleUnderlined
                      tag="h3"
                      classList="font-nandos text-xxl2 mb-2"
                      content={block.blockName}
                      colorTitle="text-black"
                      lineWidth="w-100px"
                      titleAlign="Left"
                    />
                    {block.content ? (
                      <div className="mt-10">
                        {documentToReactComponents(block.content.json)}
                      </div>
                    ) : null}
                  </div>
                ) : block.content ? (
                  <div className={`${havePadding || "pl-20 pr-20"} pt-10`}>
                    {documentToReactComponents(block.content.json)}
                  </div>
                ) : null}
              </div>
            </li>
          </ul>
        ))}
    </Slider>
  )
}

export default AboutUsSlider
